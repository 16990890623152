import React from 'react';
import { Helmet } from 'react-helmet';

interface Props {
  title?: string;
  description?: string;
  name?: string;
}

export const Seo: React.FC<Props> = ({
  title = 'Personalized Baby Book | Welcome Baby',
  description = 'Welcome Baby is the first personalized baby book for your milestones and memories. Create a story full of love & precious moments, and make your parenting journey magical!',
  name = 'Personalized Baby Book | Welcome Baby',
  children,
}) => (
  <Helmet>
    {title ? <title>{title}</title> : null}
    <meta itemProp="name" content={name} />
    <meta id="description" name="description" content={description} />
    <link rel="canonical" href={'https://welcome.baby'} />

    {/* FB tags */}
    <meta property="og:url" content="https://welcome.baby" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content={name} />
    <meta property="og:description" content={description} />
    {children}
  </Helmet>
);

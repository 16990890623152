import { useRouter } from 'apis/history';
import * as React from 'react';
import { mobile, tablet, useQuery } from 'styles/breakpoints';
import { Text } from 'components';
import styled from 'styled-components';
import { Quiz } from 'types/quiz';
import Logo from 'assets/icons/quiz/logo-footer.svg';
import Fb from 'assets/icons/quiz/facebook.svg';
import Ig from 'assets/icons/quiz/instagram.svg';

interface FooterProps {
  disclaimerText: string[];
  whiteFooter?: boolean;
}

const LCFooter: React.FC<FooterProps> = ({
  disclaimerText,
  whiteFooter = false,
}) => {
  const isBrowser = typeof window !== 'undefined';

  const { goToPrivacy, goToTerms, goToContact, goToLanding, goToReviews } =
    useRouter();
  const { isTablet } = useQuery();
  const quiz = isBrowser
    ? new URLSearchParams(window.location.search).get('qz') ?? Quiz.Main
    : '';

  return (
    <>
      <Container whiteFooter={whiteFooter}>
        <Wrapper>
          <Links>
            <LinkWrap onClick={() => goToLanding()}>
              <Logo />
            </LinkWrap>
            {quiz === Quiz.Main ? (
              <>
                <LinkWrap onClick={() => goToReviews()}>
                  <StyledTextT type="bodyM600" color="light0">
                    Reviews
                  </StyledTextT>
                </LinkWrap>
                {/* <LinkWrap>
                  <StyledTextT type="bodyM600" color="light0">
                    <StyledLink href="https://help.welcome.baby/hc/en-us">
                      Help Center
                    </StyledLink>
                  </StyledTextT>
                </LinkWrap> */}
                <LinkWrap onClick={() => goToContact()}>
                  <StyledTextT type="bodyM600" color="light0">
                    Contact Us
                  </StyledTextT>
                </LinkWrap>
              </>
            ) : null}
            <LinkWrap onClick={() => goToTerms()}>
              <StyledTextT type="bodyM600" color="light0">
                Terms & Conditions
              </StyledTextT>
            </LinkWrap>
            <LinkWrap onClick={() => goToPrivacy()}>
              <StyledTextT type="bodyM600" color="light0">
                Privacy Policy
              </StyledTextT>
            </LinkWrap>
          </Links>

          <DisclaimerContainer>
            <Wrap>
              <IconsContainer>
                <a
                  href="https://www.facebook.com/people/Welcome-Baby/61558796817807/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Fb />
                </a>
                <a
                  href="https://instagram.com/welcome_baby_official"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Ig />
                </a>
              </IconsContainer>
              {disclaimerText.map((paragraph, index) => (
                <StyledText
                  key={index}
                  type={isTablet ? 'bodyXM400' : 'bodyXM400'}
                  color="dark0"
                >
                  {paragraph}
                </StyledText>
              ))}
            </Wrap>
          </DisclaimerContainer>
        </Wrapper>
      </Container>
    </>
  );
};

export default LCFooter;

const StyledLink = styled.a`
  &:hover {
    opacity: 1 !important;
  }
`;

const IconsContainer = styled.div`
  display: flex;
  gap: 0.75rem;
`;

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding-top: 1rem;
  @media ${tablet} {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 1rem;
  }
`;

const Dot = styled.div`
  color: #fff;
`;

const DisclaimerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const DisclaimerWrap = styled.div`
  cursor: pointer;
`;

const StyledTextT = styled(Text)`
  color: #28293d;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: -0.021rem;
  @media ${tablet} {
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: auto;
  gap: 1.5rem;
  @media ${tablet} {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
`;

const StyledText = styled(Text)`
  color: #000;
  text-align: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media ${tablet} {
    font-size: 0.75rem;
  }
`;

const DisclaimerContainer = styled.div`
  width: 100%;
  border-top: 1px solid #73899C;
  display: flex;
  flex-direction: column;
  align-items: center
  gap: 0.5rem;
  @media ${tablet} {
    align-items: center;
    gap: 0.5rem;
  }
`;

const Container = styled.footer<{ whiteFooter?: boolean }>`
  display: flex;
  padding: 1.5rem 0;
  gap: 1.5rem;
  background: ${({ whiteFooter }) => (whiteFooter ? '#fff' : '#F0F0F0')};
  @media ${tablet} {
    padding: unset;
    height: unset;
    text-align: center;
    padding: 1.5rem 1rem;
  }
`;

const Links = styled.div`
  color: #fff;
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
  @media ${tablet} {
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }
`;

const LinkWrap = styled.div`
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  align-items: center;
  @media ${tablet} {
  }
`;

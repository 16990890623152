import React from 'react';
import styled from 'styled-components';
import theme from 'utils/theme';

const Checkbox = ({ checked, onChange, quizVariant, props, isRound }) => (
  <CheckboxContainer>
    <HiddenCheckbox checked={checked} onChange={onChange} {...props} />
    {quizVariant ? (
      <StyledCheckboxQuiz checked={checked} isRound={isRound}>
        <IconQuiz checked={checked} viewBox="0 0 24 24">
          <path
            d="M4.16699 10.0002L8.33366 14.1668L16.667 5.8335"
            stroke="#F5F5F5"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </IconQuiz>
      </StyledCheckboxQuiz>
    ) : (
      <StyledCheckbox checked={checked} isRound={isRound}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
    )}
  </CheckboxContainer>
);

export default Checkbox;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 1.90476px;
  margin-bottom: 1px;
`;

const IconQuiz = styled.svg<{ checked: boolean }>`
  fill: none;
  stroke: #fff;
  height: ${({ checked }) => (checked ? '1.25rem' : '0px')};
  width: ${({ checked }) => (checked ? '1.25rem' : '0px')};
  display: block;
  margin: auto;
  // stroke-width: 4px;
  position: absolute; // <-- Add this line
  top: 42%;
  left: 48%; // <-- Add this line
  transform: translate(-50%, -50%)
    scale(${props => (props.checked ? '1' : '0')}) translate(2px, 4px);
  // opacity: ${props => (props.checked ? '1' : '0')}; // <-- Adjust opacity

  stroke-width: 0.25px;
`;

const StyledCheckbox = styled.div<{ checked: boolean; isRound?: boolean }>`
  display: inline-block;
  width: 1.42856rem;
  height: 1.42856rem;
  background: ${({ checked }) => (checked ? '#EF71A0' : '#FFF')};
  border: 1px solid ${({ checked, theme }) => (checked ? '#EF71A0' : '#d6d6d6')};
  border-radius: ${({ isRound }) => (isRound ? '10rem' : '0.25rem')};
  transition: all 150ms;
  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`;

const StyledCheckboxQuiz = styled.div<{ checked: boolean; isRound?: boolean }>`
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  background: ${({ checked }) => (checked ? '#EF71A0' : '#F5F5F5')};
  border: 1px solid ${({ checked }) => (checked ? '#EF71A0' : '#D9D9D9')};
  border-radius: ${({ isRound }) => (isRound ? '10rem' : '0.25rem')};
  position: relative; // <-- Add this line
  ${Icon} {
    visibility: visible;
  }
`;

const CheckboxContainer = styled.div`
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
`;

import React from 'react';
import { wrapRootElement as wrapWithProvider } from './src/wrap-with-provider';
import theme from './src/utils/theme';
import { ThemeProvider } from 'styled-components';

export const wrapRootElement = ({ element, pathname }) => {
  console.log('pathname brow', pathname)

  if (pathname === '/') {
    return (<ThemeProvider theme={theme}>
      {element}
    </ThemeProvider>)
  } else {
    return wrapWithProvider({ element });
  }
};
